
import { defineComponent, ref, toRefs } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import {useRoute, useRouter} from "vue-router";
import * as API from "@/API/checking/spotChecking"
import useCheckingConfirmTab from "@/hooks/checking/spotChecking/useCheckingConfirm";
import {Modal} from "ant-design-vue";
import useTableHeight from "@/hooks/useTableHeight";
export default defineComponent({
  name: "spotCheckConfirmDetail",
  props: {},
  setup() {
    const { tableHeight } = useTableHeight();
    const router = useRouter()
    const route = useRoute()
    const {
      query,
      columnDetail,
      dataSource,
      businessType
    } = useCheckingConfirmTab();
    if(route.query.type === 'dealer'){
      businessType.value = 'dealer'
      API.getDealerPackageCodeDetail(route.query.packageCode as string).then((res: any)=>{
        console.log('返回dealer详情====',res)
        if(res.code === '0'){
          dataSource.value = res.data
        }
        else{
          Modal.error({
            title: 'Tips',
            content: res.message
          })
        }
      })
    }
    if(route.query.type === 'finance'){
      businessType.value = 'finance'
      API.getFinancialPayoutCodeDetail(route.query.packageCode as string).then((res: any)=>{
        console.log('返回finance详情====',res)
        if(res.code === '0'){
          dataSource.value = res.data
        }
        else{
          Modal.error({
            title: 'Tips',
            content: res.message
          })
        }
      })
    }
    // 上传相关
    const uploadVisible = ref<boolean>(false);
    const upload = () => {
      uploadVisible.value = true;
    };
    const onBatchConfirm = ()=>{
      //
    }
    const onBatchReturn = ()=>{
      //
    }
    const onConfirmSIPO = ()=>{
      //
    }
    return {
      query,
      columnDetail,
      dataSource,
      tableHeight,
      //checkConfirmTableData,
      isPagination: true,
      businessType,
      onBatchConfirm,
      onBatchReturn,
      onConfirmSIPO
    };
  },
});
