import {reactive, watch, ref, unref, computed} from 'vue'
import moment from 'moment'
import { Moment } from 'moment';
import {
    CheckingFinanceTableData,
    BUSearchParams,
    CheckingDealerTableData,
    LogTableData,
    CheckConfirmTableData
} from "@/views/Payment/BU/types"
import * as API from "@/API/checking/spotChecking"
import { useStore } from 'vuex';
import {ColumnProps} from "ant-design-vue/es/table/interface";
import {AttachmentData} from "@/views/Payment/FlRt/types";
import useTableIndex from "@/utils/payment/useTableIndex";
type Key = ColumnProps['key'];
const useDealerTab = () => {
    // 查询区数据：包括 payoutYears|payoutMonth|payoutRound|payoutCode|BU|programCode|payoutStatus
    const searchParams = reactive<BUSearchParams>({
        payoutYears: moment(),
        payoutMonth: null,
        payoutRound:[],
        payoutCode: [],
        BU:['AMG'],
        programCode:'',
        payoutStatus:[]
    });
    const state = reactive({
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        payoutYear: '9999',
        payoutMonth:'Jan.',
        payoutRound: undefined,
        payoutCode: [],
        BU:'',
        programCode:'',
        payoutStatus: [],
        entity: '',
        priority: '',
        submissionDate : ref<Moment[]>
        ([moment(moment(new Date()).subtract(2,'months').startOf('month'),'YYYY-MM-DD'),
            moment(moment().endOf('month'),'YYYY-MM-DD')])
    });
    const watchFlag = ref<boolean>(false)
    const handleChange = (val: Moment[]) => {
        state.submissionDate = val;
    };
    const mode2 = ref<string[]>(['date', 'date']);
    const handlePanelChange2 = (val: Moment[], mode: string[]) => {
        state.submissionDate  = val;
        mode2.value = [
            mode[0] === 'date' ? 'month' : mode[0],
            mode[1] === 'date' ? 'month' : mode[1],
        ];
    };
    // 上传的附件列表
    const attachmentData = ref<AttachmentData[]>([])
    const isUploadVisible = ref<boolean>(false)
    //#endregion
    //endregion
    //#region [定义rowSelectionD和rowSelectionAfc]
    const rowSelectionPayoutCode = reactive<{ selectedRowKeys: Key[]; selectedRowData: CheckingDealerTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: CheckingDealerTableData[]) => {
            rowSelectionPayoutCode.selectedRowKeys = selectedRowKeys;
            rowSelectionPayoutCode.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '30px'
    })
    const rowSelectionPending = reactive<{ selectedRowKeys: Key[]; selectedRowData: CheckingFinanceTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: CheckingFinanceTableData[]) => {
            rowSelectionPending.selectedRowKeys = selectedRowKeys;
            rowSelectionPending.selectedRowData = selectedRows;
        },
        selectedRowKeys: ref<any>([]),
        selectedRowData: ref<any>([]),
        columnWidth: '30px'
    })
//#endregion
    const columnsPayoutBatch = [
        { title: "Package Code", dataIndex: "packageCode",slots: { customRender: 'packageCode' }},
        { title: "Submit Program No. / Amount", dataIndex: "submitProgramNo",slots: { customRender: 'submitProgramNo' }, key: "submitProgramNo",align: 'right'},
        { title: " ", dataIndex: "submitProgramNoAmount",slots: { customRender: 'submitProgramNoAmount' }, key: "submitProgramNoAmount", align: 'left'},
        { title: "Spot Checked No. / Amount ", dataIndex: "spotCheckedNo",slots: { customRender: 'spotCheckedNo' }, Key:"spotCheckedNo", align: 'right'},
        { title: "", dataIndex: "spotCheckedNoAmount",slots: { customRender: 'spotCheckedNoAmount' },  align: 'left'},
        { title: "Controlling Check No. / Amount ", dataIndex: "controllingCheckedNo", key: "controllingCheckedNo",align: 'right'},
        { title: '', dataIndex: "controllingCheckedNoAmount", align: 'left'},
        { title: 'Time Line From', dataIndex: "timeLineFrom", align: 'left'},
        { title: 'To', dataIndex: "timeLineTo", align: 'left'},
        { title: 'Priority', dataIndex: "priority", align: 'center'},
        { title: "Operation", key: "operation", fixed: 'right', width:"100",  align: 'center', slots: { customRender: "actionExport"}},
        { title: 'Controlling Confirm', dataIndex: "controllingConfirm", width:"180", align: 'center', fixed: 'right', },
        { title: "Log", key: "log", fixed: 'right', width:"80",  align: 'center', slots: { customRender: "actionLog"}}

    ]
    const columnsPendingCreateBatch = [
        { title: "Payout Code", dataIndex: "payoutCode",slots: { customRender: 'payoutCode' }},
        { title: "Program No.", dataIndex: "programNo"},
        { title: "Total No. / Amount", dataIndex: "totalVinNo",align: 'right', width: '80px'},
        { title: "", dataIndex: "totalVinNoAmount", align: 'left'},
        { title: "Spot Check Passed No. / Amount",dataIndex: "spotCheckPassedVinNo",align: 'right',width: '80px'},
        { title: "", dataIndex: "spotCheckPassedVinNoAmount",align: 'left'},
        { title: "Controlling Check Passed No. / Amount", dataIndex: "controllingCheckPassedVinNo",align: 'right'},
        { title: "", dataIndex: "controllingCheckPassedVinNoAmount",align: 'left'},
        { title: 'Time Line From', dataIndex: "timeLineFrom", align: 'left'},
        { title: 'To', dataIndex: "timeLineTo",align: 'left'},
        { title: 'Priority', dataIndex: "priority"},
        { title: "Operation", key: "operation", fixed: 'right',  align: 'left', width: 100, slots: { customRender: "actionExport"}},
        { title: 'Controlling Confirm', dataIndex: "controllingConfirm", width: 180,fixed: 'right',align: 'left'},
        { title: "Log", key: "log", fixed: 'right',  align: 'left', width: 80, slots: { customRender: "actionLog"}}
    ]
    const withdrawModalVisible = ref<boolean>(false)
    const columnsLog = [
        { title: "Updater", dataIndex: "operator", width: "200"},
        { title: "Content", dataIndex: "operateContent", width: "200"},
        { title: "Update Date", dataIndex: "operateDate", width: "200"},
    ]
    const businessType = ref<string>('')
    const columnDealer = [
        {
            title: "Program Code",
            dataIndex: "programCode",
            customRender: useTableIndex(),
            width: '80px'
        },
        {
            title: "Program Name",
            dataIndex: "programName",
            slots: { customRender: 'programName' }
        },
        {
            title: "SI Offer",
            dataIndex: "siOffer",
        },
        {
            title: "Program Period From",
            dataIndex: "programPeriodFrom",
        },
        {
            title: "To",
            dataIndex: "programPeriodTo",
        },
        {
            title: "Dealer No.",
            dataIndex: "dealerNo",
        },
        {
            title: "VIN No.",
            dataIndex: "vinNo",
        },
        {
            title: "Claim No.",
            dataIndex: "claimNo",
        },
        {
            title: "SIPO Confirmed Amount",
            dataIndex: "sipoConfirmedAmount",
        },
        {
            title: "Spot Confirmed Amount",
            dataIndex: "spotConfirmedAmount",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {   title: "Operation",
            key: "operation",
            fixed: 'right',
            width:"80",
            align: 'center',
            slots: { customRender: "action"}},
    ]
    const columnFinance = [
        {
            title: "Program Code",
            dataIndex: "programCode",
            customRender: useTableIndex(),
            width: 70,
        },
        {
            title: "Program Name",
            dataIndex: "programName",
            slots: { customRender: 'programName' },
            width: 100,
        },
        {
            title: "SI Offer",
            dataIndex: "siOffer",
            width: 70,
        },
        {
            title: "Program Period From",
            dataIndex: "programPeriodFrom",
            width: 100,
        },
        {
            title: "To",
            dataIndex: "programPeriodTo",
            width: 100,
        },
        {
            title: "VIN No.",
            dataIndex: "vinNo",
        },
        {
            title: "SIPO Confirmed Amount",
            dataIndex: "sipoConfirmedAmount",
            align: "right",
        },
        {
            title: "Spot Confirmed Amount",
            dataIndex: "spotConfirmedAmount",
            align: "right",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {   title: "Operation",
            key: "operation",
            fixed: 'right',
            width:"80",
            align: 'center',
            slots: { customRender: "action"}},
    ]
    const columnDetail = computed(()=>{
        return businessType.value === 'dealer'? columnDealer : columnFinance
    })
    const dataSource = ref([])
    const tableDataPayoutBatch = ref<CheckingDealerTableData[]>()
    const tableDataPendingCreateBatch = ref<CheckingFinanceTableData[]>()
    const tableDataLog = ref<LogTableData[]>()
    const checkConfirmTableData = ref<CheckConfirmTableData>()

    const getLoglist = (id: string)=> {
        API.getLoglist(id).then((res: any)=>{
            if(res.code === '0'){
                withdrawModalVisible.value = true
                console.log('返回日志列表：',res)
                tableDataLog.value = res.data
            }
        })
    }
    const store = useStore()
    //region [获取查询区初始化 “年”和“月”]
    const doSearchPayoutCodeTable = () => {
        const paramsPayoutCodeList = {
            "bu": state.BU,
            "entity": state.entity,
            "priority": state.priority,
            "submissionPeriodEnd": state.submissionDate[1].format('YYYY-MM-DD'),
            "submissionPeriodStart": state.submissionDate[0].format('YYYY-MM-DD')
        }
        API.getDealerlist(paramsPayoutCodeList).then((res: any)=>{
            console.log('dealerlist返回结果：',res)
            if(res.code === '0'){
                tableDataPayoutBatch.value  =res.data
            }
        })
    }
    const doSearchPendingPayoutCodeTable = () => {
        const paramsPayoutCodeList = {
            "bu": state.BU,
            "entity": state.entity,
            "priority": state.priority,
            "submissionPeriodEnd": state.submissionDate[1].format('YYYY-MM-DD'),
            "submissionPeriodStart": state.submissionDate[0].format('YYYY-MM-DD')
        }
        API.getFinancialList(paramsPayoutCodeList).then((res: any)=>{
            console.log('financelist返回结果：',res)
            if(res.code === '0'){
               tableDataPendingCreateBatch.value  =res.data
            }
        })
    }
    //#endregion
    //region [Search 按钮]
    const doSearch = () => {
        doSearchPayoutCodeTable()
        doSearchPendingPayoutCodeTable()
    }
    //#endregion
    const query = reactive({
        packageCode: "",
        programNo: "",
        claimNo: "",
        timeLine: "",
        priority: "",
        assignee: "",
        checkStatus: "",
        checkStatusOptions: [],
    });
    const totalCount = computed(() => {
        debugger
        let total = 0
        total += tableDataPayoutBatch.value ? tableDataPayoutBatch.value.length : 0
        total += tableDataPendingCreateBatch.value ? tableDataPendingCreateBatch.value.length : 0
        return total
    });
    return {
        state,
        isUploadVisible,
        searchParams,
        rowSelectionPayoutCode,
        rowSelectionPending,
        columnsPayoutBatch,
        columnsPendingCreateBatch,
        tableDataPayoutBatch,
        tableDataPendingCreateBatch,
        doSearch,
        attachmentData,
        handlePanelChange2,
        mode2,
        handleChange,
        doSearchPayoutCodeTable,
        doSearchPendingPayoutCodeTable,
        columnsLog,
        tableDataLog,
        getLoglist,
        withdrawModalVisible,
        query,
        columnDetail,
        checkConfirmTableData,
        businessType,
        dataSource,
        totalCount
    }
}
export default useDealerTab