import { h } from "vue";
const useTableIndex = (pagination: any = undefined) => {
  return ({ index }: { index: number }): any => {
    const content =
      pagination === undefined
        ? index + 1
        : index + 1 + (pagination.pageNumber - 1) * pagination.pageSize;
    return h("span", content);
  };
};
export default useTableIndex;
